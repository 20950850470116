import React from "react"
import ReactPlayer from "react-player"
import styles from "./PlayerVideo.module.css"
import PropTypes from "prop-types";
import classnames from "classnames";

const PlayerVideo = ({ url, title }) => {

  if (!url) {
    return null
  }

  const titleclass = classnames('ma0 montserrat f46 center te-papa-green fw7',styles.titleH2)

  return (
    <>
      {title && (
        <h2 className={titleclass}>
          {title}
          <hr className={styles.titleHr}/>
        </h2>
      )}
      <ReactPlayer
        url={url}
        config={{
          youtube: {
            playerVars: { controls: 1 },
          },
        }}
        width="100%"
        height="100%"
        className={styles.player}
      />
    </>
  )
}

PlayerVideo.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
}

export default PlayerVideo
