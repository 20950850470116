import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import _get from "lodash/get"
import classnames from "classnames"

import Button from "../components/Button"
import Hero from "modules/Hero"
import { Icon } from "components/Icons"
import PlayerVideo from "components/PlayerVideo"
import Benefits from "components/Benefits"
import FormLead from '../components/FormLead'
import styles from "./product.module.css"

const ProductPage = ({location}) => {
    const [modal, setModal] = useState(false);
    const [productSelected, setProductSelected] = useState("");
    const [data, setData] = useState({});
    const haveComplement = !!data.additionalProduct;

    useEffect(() => {
        const { state } = location;
        if (!state) {
            navigate('/');
            return;
        }
        setData(state);
    })

    useEffect(() => {
        function onKeyup(e) {
            if (e.key === 'Escape') history.go(-1)
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            navigate("/");
        }
    };

    const heroProps = {
        title: data.title,
        description: data.description,
        imageUrl: data.imageUrl,
        imageMobileUrl: data.imageMobileUrl,
        onClick: () => handleShowModal({ target: { id: data.title } }),
        textLegal: data.text_legal,
    };

    const playerVideoProps = {
        title: `Vídeo ${data.name}`,
        url: data.video_url,
    };

    const benefitsProps = {
        data: data.benefits,
    };

    const heroComplementProps =
        haveComplement && {
            title: data.additionalProduct.title,
            description: data.additionalProduct.description,
            imageUrl: _get(data.additionalProduct.image, "data.full_url"),
            imageMobileUrl: _get(data.additionalProduct.image_mobile, "data.full_url"),
            onClick: () => handleShowModal(),
            textLegal: data.additionalProduct.text_legal,
        };

    const propsButton = {
        text: "Falar com Gerente de Soluções",
        color: "supernova",
        gtmClass: "GTM_SOLUTIONS_MANAGER_MAIN",
        onClick: () => handleShowModal(),
    };

    const handleShowModal = () => {
        setProductSelected(data.title);
        setModal(true);
    };

    const handleCloseModal = () => {
        setModal(false);
        setProductSelected("");
    };

    const containerPlayerVideoClasses = classnames(
        "mc center flex flex-column items-center",
        styles.containerPlayerVideo
    );

    const headerClasses = classnames(
        "mc center flex flex-between ma0 pa0 mt24-l mt24-m pt24-l pt24-m mt40"
    );

    const buttonLikeLinkClasses = classnames(
        "bg-transparent no-underline pointer bn pa0 ma0",
        styles.outline
    );

    const linkBackClasses = classnames(buttonLikeLinkClasses, "flex items-center");

    const colobodyClasses = classnames("montserrat", styles.colobody);

    const buttonClasses = classnames(
        "lh-copy f16 montserrat fw6 fs-normal tc mb24 mt24 mt0-l mt0-m mb48-l mb48-m"
    );

    return (
        <>
            <Helmet htmlAttributes={{ lang: "pt-BR" }}>
                <title>
                    Sua empresa digital, fale com consultor agora | Algar Telecom
                </title>
                <meta
                    name="description"
                    content="Fale com consultor agora, conheça os nossos produtos para fazer a transformação digital da sua empresa. Link dedicado, office 365, proteção contra ataque a site, backup na nuvem, servidor na nuvem"
                />
                <meta charSet="utf-8" />
                <body className={colobodyClasses} />
            </Helmet>

            <header
                id="Soluções para sua empresa"
                title={`${productSelected}_solução`}
                className={headerClasses}
            >
                <div className="flex items-center justify-between w-100">
                    <button
                        className={linkBackClasses}
                        onClick={() => navigate(-1)}
                        onKeyPress={handleKeyPress}
                        aria-label="Voltar a home"
                    >
                        <Icon name="Voltar" aria-label="Voltar" title="Voltar" />
                        <span className="ml12 f20 fw4 te-papa-green dn db-ns">Voltar</span>
                    </button>

                    <button
                        className={buttonLikeLinkClasses}
                        onClick={() => navigate(-1)}
                        onKeyPress={handleKeyPress}
                        aria-label="Voltar a home"
                    >
                        <h1 className="ma0 pa0">
                            <Icon
                                name="LogoColor"
                                role="img"
                                aria-label="Logo Algar"
                                title="Logo Algar"
                                width={122}
                                height={48}
                            />
                            <span className="screen-reader-only">
                                Empresa Digital - Algar Telecom
                            </span>
                        </h1>
                    </button>

                    <button
                        className={buttonLikeLinkClasses}
                        onClick={() => navigate(-1)}
                        onKeyPress={handleKeyPress}
                        aria-label="Voltar a home"
                    >
                        <Icon
                            name="Fechar"
                            aria-label="Fechar"
                            title="Fechar"
                            className={styles.iconClose}
                        />
                    </button>
                </div>
            </header>

            {modal && (
                <FormLead
                    product={productSelected}
                    handleCloseModal={() => handleCloseModal()}
                />
            )}
            <Hero {...heroProps} />

            <div id="conteudo-principal" className={containerPlayerVideoClasses}>
                <PlayerVideo {...playerVideoProps} />
            </div>

            <section className="mc center">
                <Benefits {...benefitsProps} />
            </section>

            <div className="flex items-center justify-center">
                <Button {...propsButton} size="medium" className={buttonClasses} />
            </div>

            {haveComplement && (
                <div className="pb32">
                    <Hero {...heroComplementProps} />
                </div>
            )}
        </>
    );
};


export default ProductPage;
