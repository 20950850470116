import React from 'react'
import classnames from 'classnames'
import _split from 'lodash/split'
import _size from 'lodash/size'

import MediaQuery from 'react-responsive'
import { Row, Col } from "antd"
import Button from '../../components/Button';

import styles from './hero.module.css';

const Hero = ({ title, textLegal, description, imageUrl, imageMobileUrl, onClick }) => {
  
  const containerClasses = classnames('mc center pt24 pt0-l pt0-m pt0-ns bg-white', styles.container);
  const imageClasses = classnames('h-100 br2', styles.imageSize);
  const descriptionClasses = classnames('ma0 pa0 mb16-l mb16-m mb12-ns mb12 f12 f14-l f14-m lh-copy te-papa-green mt4');
  const propsButton = {
    text: "Falar com Gerente de Soluções",
    color: "supernova",
    gtmClass: "GTM_SOLUTIONS_MANAGER_MAIN",
    onClick: onClick
  }

  const Title = ({ title }) => {
    const titles = _split(title, ':');
    const defaultTitleClasses = classnames("f20 f32-l f32-m tl lh-copy te-papa-green mb24 relative w-80-l w-100-m w-100-ns", styles.title)

    if(_size(titles) <= 1){
      return(
        <h2 className={defaultTitleClasses}>{title}</h2>
      )
    }

    return (
      <h2 className={defaultTitleClasses}>
        <span className="ma0 pa0 fw4">{`${titles[0]}: `}</span>
        <span className="ma0 pa0 b">{titles[1]}</span>
      </h2>
    );
  }

  return (
    <section className={containerClasses}>
      <div className="flex justify-center items-center">
        <Row className="" gutter={{ sm: 24, xs: 0, md: 24, lg: 24, xl: 24 }}>
          <Col span={24} md={12} lg={12} xl={12}>
            <div className="center flex flex-column h-100 w-80-m w-80-l ph16 ph0-l ph0-m pt24-ns pt48-l pt48-m">
              <div>
                <Title title={title} />
                <p className={descriptionClasses}>{description}</p>
              </div>
              <div className="pb32 pb0-ns">
              <MediaQuery query="(max-width: 1023px)">
                    <Button
                      {...propsButton}
                      size="small"
                    />
                </MediaQuery>
                <MediaQuery query="(min-width: 1024px)">
                    <Button
                      {...propsButton}
                      size="medium" 
                    />
                </MediaQuery>
                {textLegal && <span className="db f12 fw4 te-papa-green mt16 mb16 mb0-ns">{textLegal}</span>}
              </div>
            </div>
          </Col>

          <Col className="mt24 mt0-l mt0-m mt0-ns" span={24} md={12} lg={12} xl={12}>
            <MediaQuery query="(max-width: 768px)">
              <img className={imageClasses} src={imageMobileUrl} alt={title} />  
            </MediaQuery>
            <MediaQuery query="(min-width: 769px)">
              <img className={imageClasses} src={imageUrl} alt={title} />
            </MediaQuery>
          </Col>
        </Row>
      </div>

    </section>
  )
}

export default Hero;