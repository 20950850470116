import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _size from "lodash/size";
import _split from 'lodash/split';
import styles from "./Benefits.module.css";

const Benefits = ({ data}) => {

  if (!data) return null

  const containerClasses = classnames(
    "flex justify-center items-center pv32-l pv32-m mb32-l mb32-m pv24",
    _size(data) > 1 ? 'flex-row-l flex-row-m flex-row-ns flex-column' : 'flex-column'
  );

  return (
    <div className="pb48">
      <article className={containerClasses}>

        {_map(data, (item, index) => {

          const {title, advantages, directusId } = item
          const benefitsList = _split(advantages, ';')
          const isFirst =  _size(data) > 1 && index == 0
          const benefitisListLenght = _size(benefitsList)

          const containerClasses = classnames('pv48 ph48', styles.wrapper, isFirst ? styles.marginRight : '');

          return (

            <div key={`${directusId}-${title}`} className={containerClasses}>
              <div className="flex items-center">
                <Title>
                  {title}
                  <hr className={styles.titleHr}/>
                </Title>
              </div>
              <div className="flex flex-column">
                {_map(benefitsList, (item, id) => {
                  const label = id === benefitisListLenght - 1 ? `${item}.` : `${item};` 

                  return <Description key={`${id}-${item}`}>{label}</Description>
                })}
              </div>
            </div>
          )
        })}
      </article>
    </div>

  )
}

const Title = ({ children }) => {

  if (!children)  return null
  
  const titleClasses = classnames(
    "ma0 b te-papa-green montserrat f32-l f32-m f24 lh-copy",
    styles.title
  )

  return <h3 className={titleClasses}>{children}</h3>
}

const Description = ({ children }) => {

  if (!children) return null
  

  const descriptionClasses = classnames(
    "ma0 te-papa-green montserrat f20-l f20-m f16 lh-copy "
  )

  return <span className={descriptionClasses}>{children}</span>
}

Benefits.propTypes = {
  data: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}

export default Benefits
